import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../config/axiosConfig';

export const fetchCollectionsList = createAsyncThunk(
  "fetchCollectionsList",
  async ({projectId}, { dispatch, rejectWithValue , history}) => {
    try {
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/projects/${projectId}/collections/`,
        params: {}
      });
      if (res.status === 200) {
        return res.data.collections;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPublicCollection = createAsyncThunk(
  'fetchPublicCollection',
  async ({collectionId}, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'get',
        url: '/api/v1/public_apis/collection',
        params: {
          collection_id: collectionId
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importCollection = createAsyncThunk(
  'collections/importPostman',
  async ({projectId, s3Url, importType}, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/collection_imports/`,
        data: {
          project_id: projectId,
          s3_url: s3Url,
          import_type: importType
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forkCollection = createAsyncThunk(
  'collections/fork',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/fork`,
        params: {
          project_id: _.projectId,
          collection_name: _.collectionName,
        }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err?.payload?.response);
    }
  }
);

export const makeCollectionPublic = createAsyncThunk(
  'collections/makePublic',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/make_public`,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const makeCollectionPrivate = createAsyncThunk(
  'collections/makePrivate',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "get",
        url: `/api/v1/collections/${_.collectionId}/make_private`,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const importCollectionViaURL = createAsyncThunk(
  'collections/importPostman',
  async ({projectId, external_url, import_type}, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/collection_imports`,
        data: {
          project_id: projectId,
          external_url: external_url,
          import_type: import_type
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const exportCollection = createAsyncThunk(
  'collections/exportCollection',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: "post",
        url: `/api/v1/collections/${args.collectionId}/export`,
      });

      console.log("response data is ", res.data);

      if (res.status === 200) {
        return res.data;
      }

      return rejectWithValue('Unexpected response status'); // In case status is not 200

    } catch (err) {
      // Handle cases where err.response is undefined
      return rejectWithValue(err.response?.data || 'Network error or unexpected issue');
    }
  }
);

